import Vue from 'vue'

// インターフェースを追加
declare module 'vue/types/vue' {
  interface Vue {
    $$const: { [key: string]: any }
  }
}

// 定数
export const $$const = {
  // APIレスポンスパラメータ
  API_RESPONSE: {
    MST_STATUS: {
      SUCCESS: 0, // 正常終了
      FAILED: 1, // 異常終了
      INVALID_PARAM: 8, // パラメータ不備
      AUTH_FAILED: 9, // 認証失敗
      EDIT_FAILED: 10, // 編集処理失敗
    },
    SRCH_STATUS: {
      SUCCESS: 0, // 検索正常終了
      FAILED: 1, // 検索異常終了
      INTERRUPT: 2, // パラメータ正常だが検索未実施
      INVALID_PARAM: 8, // パラメータ不備
    },
    SRCH_ERR_CODE: {
      SYSTEM_MAINTENANCE: 'MSTEK010129', // システムメンテナンス中です。
      SHIMEN_DB_LIMIT_EXCEEDED: 'MSTEK010144', // ※検索結果が10万件を超えました。絞込み検索を実施してください。
    },
    L_OPT_PHOTO: {
      INAVAILABLE: '0', // 利用不可
      READ: '1', // 閲覧のみ
      DETAIL: '2', // 詳細情報可能
      EDITABLE: '3', // 編集可
    },
    L_SVSTATUS: {
      STOP: 0, // サービス停止中
      FREE: 1, // 無料モニタ中
      PAID: 2, // 有料サービス
    },
    SV_STATE: {
      STOP: '0', // サービス停止中
      PROVIDE: '1', // サービス提供中
      MAINTENANCE: '2', // メンテナンス中
    },
    SV_OSHIRASE1_TYPE: {
      OTHER: '0', // その他
      INFO: '1', // お知らせ
      MAINTENANCE: '2', // メンテナンス
    },
    SV_OSHIRASE2_TYPE: {
      OTHER: '0', // その他
      INFO: '1', // お知らせ
      MAINTENANCE: '2', // メンテナンス
    },
    GaihanCtl: {
      ALL_OK: 'Y', // 全て可
      KIRINUKI_NG: 'A', // 切り抜きのみ不可
      SHOSHI_MIDASHI_OK: 'B', // 書誌・見出しのみ可
      ALL_NG: 'N', // 全て不可
    },
  },

  // APIリクエストパラメータ
  API: {
    // ドメイン名
    domainNm:
      window.localStorage.getItem('domain') !== null
        ? String(window.localStorage.getItem('domain'))
        : document.domain,

    // 検索単位
    srchUnit: {
      SINGLE: 'S', // 単独検索
      CROSS: 'C', // 横断検索
      CONFORMITY_ORDER: 'F', // 適合度順検索
    },

    // 検索対象データ識別子
    srchId: {
      KIJI: 'T', // 記事検索
      SHIMEN: 'S', // 紙面DB
      CHIEZO: 'C', // 知恵蔵
      JIMBUTSU: 'J', // 人物DB
      GRAPH: 'G', // アサヒグラフ
      ENGLISH: 'E', // 英文DB
      OCR: 'R', // 紙面OCR復刻版
    },

    // 異体字フラグ
    itaijiFlg: {
      FALSE: 0, // 含めない
      TRUE: 1, // 含む
    },

    // 同義語フラグ
    dougigoFlg: {
      FALSE: 0, // 含めない
      TRUE: 1, // 含む
    },

    // 広告フラグ
    adFlg: {
      FALSE: 0, // 含めない
      TRUE: 1, // 含む
    },

    // 検索キーワード
    keyword: {
      EXCLUDE_AD: ' NOT （広告）', // 広告除外用
    },

    // 分類連結子
    bunruiConn: {
      AND: '&',
      OR: '+',
    },

    // 記事フラグ
    kijiTFlg: {
      FALSE: 0, // 対象外
      TRUE: 1, // 対象
    },

    // 広告フラグ
    kijiCFlg: {
      FALSE: 0, // 対象外
      TRUE: 1, // 対象
    },

    // 故人を除く
    kojinFlg: {
      FALSE: 0, // 除かない
      TRUE: 1, // 除く
    },

    // 発行期間
    srchTerm: {
      THREE_MONTH: '3M', // 3カ月
      SIX_MONTH: '6M', // 6カ月
      ONE_YEAR: '1Y', // 1年
      ALL: 'ZZ', // 全期間
      FROM_TO: 'FT', // 期間指定
    },

    // 検索年代
    srchAge: {
      MEIJI: '1', // 明治・大正
      SENZEN: '2', // 昭和（戦前）
      SENGO: '3', // 昭和（戦後）
      HEISEI: '4', // 平成
    },

    // 紙誌名
    shishiName: {
      ASAHI_SHIMBUN: 'N', // 朝日新聞
      ASAHI_SHIMBUN_DIGITAL: 'D', // 朝日デジ
      AERA: 'P', // ＡＥＲＡ
      SHUKAN_ASAHI: 'W', // 週刊朝日
      OCR: 'R', // 紙面OCR復刻版
    },

    // 検索対象
    keywordTarget: {
      MIDASHI: 'M', // 見出し（Headline）
      HONBUN: 'H', // 本文（Text)
      KEYWORD: 'K', // キーワード（Keyword)
      BUNRUI: 'R', // 分類
      BYLINE: 'B', // 署名（Byline)
      MIDASHI_HONBUN_KEYWORD: 'M,H,K',
      MIDASHI_HONBUN: 'M,H',
      MIDASHI_KEYWORD: 'M,K',
      MIDASHI_KEYWORD_BUNRUI: 'M,K,R',
    },

    // 対応語句
    wordType: {
      IPPANYOGO: 'I', // 一般用語
      RYAKUGO_GAIRAIGO: 'R,G', // 略語/外来語
    },

    // 刊種別
    kanshuName: {
      MORNING: 'M', // 朝刊
      EVENING: 'E', // 夕刊
      GOUGAI: 'G', // 号外
      FUROKU: 'F', // 付録
      EXTRA: 'X', // 別冊
    },

    // 面種別
    menshuAName: {
      HONSHI: 'H', // 本紙／本誌
      REGION: 'L', // 地域面
      GAICHI: 'G', // 外地版
    },

    // 発行社
    hakkoushaName: {
      TOKYO: 'T', // 東京
      OSAKA: 'O', // 大阪
      NAGOYA: 'N', // 名古屋
      SEIBU: 'S', // 西部
      HOKKAIDO: 'H', // 北海道
    },

    // 写真図表
    kirinukiUseReq: {
      FALSE: 0, // 含めない
      TRUE: 1, // 含む
    },

    // 表示内容
    dspNaiyo: {
      MIDASHI_ONLY: 0, // 見出しのみ
      MIDASHI_KEYWORD: 1, // 見出しとキーワード
    },

    // 順序
    dspOrder: {
      OLDEST_TO_NEWEST: 0, // 古い順
      NEWEST_TO_OLDEST: 1, // 新しい順
      JAPANESE_SYLLABARY: 2, // 50音順
      RELEVANCE: 3, // 関連度順
    },

    // 検索タイプ
    dspType: {
      START: 'START', // 初回
      RESTART: 'RESTART', // 再検索
      FIRST: 'FIRST', // 先頭ページへ
      NEXT: 'NEXT', // 次ページへ
      PREV: 'PREV', // 前ページへ
    },

    // サムネイル対象フラグ
    thumbnailFlg: {
      FALSE: 0, // 対象外
      TRUE: 1, // 対象
    },

    // 実イメージ種類
    srchKind: {
      KIRINUKI: 'T', // 切り抜きイメージ
      SHIMEN: 'S', // 紙面イメージ
      GRAPH: 'G', // アサヒグラフ
      ENGLISH: 'E', // 英文DB
    },

    // 編集フラグ
    editFlg: {
      NOT_EDIT: 0, // 編集無し
      IMGCHG_JPEG: 1, // イメージ変更(JPEG)
      IMGCHG_TIMERPDF: 2, // イメージ変更(タイマー設定PDF)
      PRTAREA_JPEG: 3, // 印刷範囲指定(JPEG)
      HEADFOOT_JPEG: 4, // ヘッダフッタ加工(JPEG)
      IMGCHG_JPEG_NOTHF: 5, // イメージ変更・ヘッダーフッター加工無(JPEG)
    },

    markFlg: {
      FALSE: 0, // 表示なし
      TRUE: 1, // 表示あり
    },

    // 取得方法フラグ
    retKindFlg: {
      FORMAT_JSON: 0, // JSON形式
      BINARY: 1, // 実データ（バイナリ）のみ
    },

    // ファイル保存フラグ
    fhznflg: {
      FALSE: false, // 保存しない
      TRUE: true, // 保存
    },

    // 検索対象オプション
    srchOpt: {
      HONSHI_KW: 0, // 本紙KW検索
      HONSHI_DATE: 1, // 本紙日付検索
      HONSHI_KW_NOHISTORY: 2, // 本紙KW検索(履歴登録無し)
      WITH_HISTORY: 0, // キーワード検索（履歴登録有り）※英文ニュースで使用
      NO_HISTORY: 2, // キーワード検索（履歴登録無し）※英文ニュースで使用
    },

    // 個別詳細取得フラグ
    getDetail2102_flg: {
      FALSE: 0,
      TRUE: 1,
    },

    // 大量アクセス検知フラグ
    accessChkFlg: {
      TRUE: '1', // 検知する
    },
  },

  // APIエラーメッセージ
  API_ERROR_MSG: {
    COMMON: 'データの取得に失敗しました。',
    VERSION:
      '朝日新聞クロスサーチの最新バージョンをご利用いただくために、PCで閲覧されている場合にはCtrl+F5等でWebブラウザを更新してください。スマホ・タブレットで閲覧されている場合には更新ボタン等でWebブラウザを更新してください。こちらは、Webブラウザのバージョンではなく朝日新聞クロスサーチのバージョンを更新するための操作です。',
  },

  // フォーム
  FORM: {
    // 接続詞
    operator: {
      AND: ' AND ',
      OR: ' OR ',
      NOT: ' NOT ',
      OPEN_PARENTHESIS: ' (',
      CLOSE_PARENTHESIS: ')',
    },

    // 上旬、中旬、下旬
    daySeason: {
      EARLY: 'early',
      MID: 'mid',
      LATE: 'late',
    },

    // 発行日接続詞
    hakkouDateConjunction: {
      FROM: 'from',
      ONLY: 'only',
      UNTIL: 'until',
    },

    // 検索年代 開始終了年
    srchAgePeriod: {
      MEIJI: {
        START: 1879,
        END: 1926,
      },
      SENZEN: {
        START: 1926,
        END: 1945,
      },
      SENGO: {
        START: 1945,
        END: 1989,
      },
      HEISEI: {
        START: 1989,
        END: 1999,
      },
    },
  },

  // 横断検索結果表示件数（20件以内）
  XSEARCH_RESULT_DISPLAY_CNT: 5,

  // デバイス
  DEVICE: {
    MOBILE_WIDTH: 768, // モバイル判定用横幅上限値
    WIDE_SCREEN_WIDTH: 1200, // ワイドスクリーン判定に使用
  },

  // ストレージのキー
  STORAGE_KEY: {
    // リダイレクト用パス名
    REDIRECT_PATH: 'redirect_path',

    // エラーページ中のリンク用ログインパス名
    LOGIN_PATH: 'login_path',
  },

  // 静的ページ
  STATIC_PAGE: {
    termsOfUse: {
      朝クロサーチB: '/exp/jpn/business/rule.html',
      朝クロサーチ中国: '/exp/eng/library/rule.html',
      朝クロサーチ海外: '/exp/eng/library/rule.html',
      朝クロサーチ中国2: '/exp/eng/library/rule.html',
      朝クロサーチ海外2: '/exp/eng/library/rule.html',
    },
    ja: {
      termsOfUse: '/exp/jpn/library/rule.html',
      about: '/help/jpn/about.html',
      howToUse: '/help/jpn/help.html',
      systemRequirements: '/exp/jpn/system_requirements.html',
      copyright: '/exp/jpn/copyright.html',
      accessibility: '/exp/jpn/accessibility.html',
      db: 'http://www.asahi.com/information/db/2forl.html',
      aboutId: '/exp/jpn/about_id/',
    },
    en: {
      termsOfUse: '/exp/eng/library/rule.html',
      about: '/help/eng/about.html',
      howToUse: '/help/eng/help.html',
      systemRequirements: '/exp/eng/system_requirements.html',
      copyright: '/exp/eng/copyright.html',
      accessibility: '/exp/eng/accessibility.html',
      db: 'http://www.asahi.com/information/db/2forl.html',
      aboutId: '/exp/jpn/about_id/',
    },
  },
}

Vue.prototype.$$const = $$const
